import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-xhr-backend';
import translations_de from "./translations/translation-de";
import translations_en from "./translations/translation-en";
import translations_fr from "./translations/translation-fr";
import translations_sv from "./translations/translation-sv";
import translations_it from "./translations/translation-it";
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
    // .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        defaultNS: 'translation',
        whitelist: ['en','de','fr','sv','it'],
        interpolation: {
            escapeValue: false,
        },
        resources : {
            en : {
                translation : translations_en,
                // peoples : peoples_en,
            },
            de : {
                translation : translations_de,
                // peoples : peoples_de,
            },
            fr : {
                translation : translations_fr,
                // peoples : peoples_fr,
            },
            sv : {
                translation : translations_sv,
                // peoples : peoples_fr,
            },
            it : {
                translation : translations_it,
                // peoples : peoples_fr,
            }
        }
        // backend: {
        //     loadPath: `./locales/{{lng}}/{{ns}}.json`
        // },
    });

export default i18n;
