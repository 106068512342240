import React, { Component } from "react";
import i18n from 'i18next';

const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

class TermsConditions extends Component {
    render() {
        if (getCurrentLng()==="en" || getCurrentLng()==='') {
            return (
                <div className="sections">
                    <section className="standard-text">
                        <div className="container">
                            <h1 className="title">Terms & Conditions</h1>
                            <div className="text">
                                <p><strong>Legal Conditions</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem and its operating divisions and subsidiaries (collectively &bdquo;Xylem&rdquo;) maintain and operate this and other websites (collectively the &bdquo;Xylem Sites&rdquo;) for informational purposes only.</p>
                                <p>&nbsp;</p>
                                <p><strong>Registration for sites</strong></p>
                                <p>&nbsp;</p>
                                <p>Some pages of the Xylem Site may be password protected. In the interest of safety and security of the business transactions, only registered users may access said pages. Xylem reserves the right to deny registration to any user. Xylem particularly reserves the right to determine certain sites, which were previously freely accessible, subject to registration. Xylem is entitled, at any time and without obligation to give reasons, to deny the user the right to access the password-protected area by blocking the user, in particular if the user:</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>Uses false data for the purpose of registration</li>
                                    <li>Violates these terms and conditions</li>
                                    <li>Neglects its duty of care with regard to user data</li>
                                    <li>Violates any applicable laws in the access to or use of the Xylem website</li>
                                    <li>Did not use the Xylem website for a long period</li>
                                    <li>For registration, the user shall give accurate information and, where such information changes over time, update such information (to the extent possible: online) without undue delay. The user shall ensure that its e-mail address, as supplied to Xylem, is current at all times and an address at which the user can be contacted.</li>
                                    <li>Upon registration the user will be provided with an access code, comprising a User ID and a password (&bdquo;User Data&rdquo;). On first access the user shall promptly change the password received from Xylem into a password known only to the user. The User Data allows the user to view or change its data or, as applicable, to withdraw its consent to data processing.</li>
                                    <li>The user shall ensure that User Data is not accessible by third parties and is liable for all transactions and other activities carried out under its User Data. At the end of each online session, the user shall log-off from the password-protected websites. If and to the extent the user becomes aware that third parties are misusing its User Data, the user shall notify Xylem thereof without undue delay in writing, or, as the case may be, by e-mail. After receipt of this notice, Xylem will endeavor to deny access to the password-protected area under such User Data. Access by the user will only be possible again upon the user&rsquo;s application to Xylem or upon new registration.</li>
                                    <li>The user may at any time request termination of registration in writing, provided that the deletion will not violate the proper performance of contractual relationships. In such event Xylem will remove all User Data and other stored personally identifiable data of the user as soon as these data are no longer needed.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Information and Documents</strong></p>
                                <p>&nbsp;</p>
                                <p>By using the Xylem Sites or downloading documents, materials or information (hereinafter &bdquo;Material&rdquo;) from them, you agree to be bound by the Legal Conditions set forth in this notice. If you do not wish to be bound by these Legal Conditions, leave immediately, do not use and do not download any Material from this or any other Xylem Site.</p>
                                <p>&nbsp;</p>
                                <p><strong>International Users, Choice of Law and Jurisdiction</strong></p>
                                <p>&nbsp;</p>
                                <p>The Xylem Sites and the Material contained in them shall be deemed to be provided in the state of New York in the United States of America and you agree that the law governing these Legal Conditions or your use of a Xylem Site shall be the laws of the state of New York without regard to any principles of conflicts of law. You further agree that any disputes arising under these Legal Conditions or from your use of a Xylem Site shall be submitted exclusively to the courts in the state of New York and that you will not assert jurisdiction in any other court or tribunal anywhere in the world. Xylem makes no representation that the Material at the Xylem Sites is appropriate or available for use at locations outside of the United States. Access to the Materials from territories where the Materials are illegal is prohibited.</p>
                                <p>&nbsp;</p>
                                <p><strong>Third-party Sites</strong></p>
                                <p>&nbsp;</p>
                                <p>As a convenience to you, Xylem may provide links to websites operated by other entities, but makes no warranty or representation whatsoever about any linked site and does not imply an affiliation or association with any such site. If you visit or use a linked site, you do so at your own risk.</p>
                                <p>&nbsp;</p>
                                <p><strong>Disclaimers</strong></p>
                                <p>&nbsp;</p>
                                <p>All materials on, distributed through or accessible from, the Xylem Sites are provided &bdquo;as is&rdquo; and &bdquo;as available,&rdquo; without warranty of any kind, expressed or implied including but not limited to the implied warranties of merchantability, fitness for particular purpose, or non-infringement. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.</p>
                                <p>&nbsp;</p>
                                <p>Xylem does not warrant the accuracy or completeness of the materials, including but not limited to the reliability of any advice or statement displayed or distributed through the Xylem Sites and your reliance on any such materials, advice, statement or information shall be at your sole risk.</p>
                                <p>&nbsp;</p>
                                <p>Xylem shall not be liable for any damages to, including any damages from viruses that may infect your computer equipment or other property by virtue of your access to any Xylem Site or your downloading of any materials therefrom.</p>
                                <p>&nbsp;</p>
                                <p><strong>Limitation of Damages</strong></p>
                                <p>&nbsp;</p>
                                <p>In no event shall Xylem be liable for any direct, special, indirect, consequential, punitive or other damages of any kind whatsoever resulting from any cause whatsoever, including but not limited to loss of use, loss of profits, or loss of data whether in an action under the theories of contract, negligence, or strict liability or otherwise, arising out of or in any way connected with the use of any Xylem Site or any materials, information or documents on or accessed through any Xylem Site.</p>
                                <p>&nbsp;</p>
                                <p><strong>Limited License</strong></p>
                                <p>&nbsp;</p>
                                <p>By your acceptance of the Legal Conditions contained herein: you agree not to deep link or frame link to any Xylem Site for any purpose without the express written permission of Xylem; and, Xylem grants you permission to view, download and copy Material provided that (a) all copyright and proprietary notices are retained, (b) the Materials are not publicly displayed, publicly distributed, or modified in any way and that no graphics are used separately from its accompanying text, and (c) any use of the Material is for informational and non-commercial or personal use only.</p>
                                <p>&nbsp;</p>
                                <p><strong>Information Provided By You</strong></p>
                                <p>&nbsp;</p>
                                <p>Do not send any confidential or proprietary information to Xylem via a Xylem Site. You agree that any information you so send will not be considered confidential or proprietary and you grant Xylem an unrestricted, irrevocable, worldwide, royalty-free license to use, reproduce and distribute any such information.</p>
                                <p>&nbsp;</p>
                                <p><strong>Changes</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem reserves the right to change these Legal Conditions at any time. Any such changes or modifications will be effective immediately when such changed or modified Legal Conditions are posted on the Xylem Sites. Your use of a Xylem Site after the modified or changed Legal Conditions are posted shall be your acceptance of that modified or changed Legal Condition. From time to time, you should review the Legal Conditions posted on the Xylem Sites to see the then current Legal Conditions.</p>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else if (getCurrentLng()==="de") {
            return (
                <div className="sections">
                    <section className="standard-text">
                        <div className="container">
                            <h1 className="title">Nutzungsbedingungen</h1>
                            <div className="text">
                                <p><strong>Rechtliche Bedingungen</strong></p>
                                <p>&nbsp;</p>
                                <p>Das Unternehmen Xylem, seine Unternehmensbereiche und Tochterunternehmen (zusammen &bdquo;Xylem&ldquo;) pflegen und betreiben diese mobile App, <a href="http://www.waterdrop.app">www.waterdrop.app</a> und andere Websites (zusammen die &bdquo;Xylem-Sites&ldquo;) lediglich zu Informationszwecken.</p>
                                <p>&nbsp;</p>
                                <p><strong>Registrierung f&uuml;r Sites</strong></p>
                                <p>&nbsp;</p>
                                <p>Einige Seiten der Xylem-Website k&ouml;nnen kennwortgesch&uuml;tzt sein. Im Interesse des Schutzes und der Sicherheit von gesch&auml;ftlichen Transaktionen haben nur registrierte Nutzer Zugang zu genannten Seiten. Auf eine Registrierung durch Xylem besteht kein Anspruch. Xylem beh&auml;lt sich insbesondere das Recht vor, auch bisher frei zug&auml;ngliche Sites einer Registrierungspflicht zu unterwerfen. Xylem ist jederzeit berechtigt, die Zugangsberechtigung zum kennwortgesch&uuml;tzten Bereich durch Sperrung des Benutzers zu widerrufen, ohne dass es der Angabe von Gr&uuml;nden bedarf, insbesondere wenn der Nutzer:</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>Zur Registrierung falsche Angaben gemacht hat</li>
                                    <li>Diese Gesch&auml;ftsbedingungen nicht beachtet hat</li>
                                    <li>Gegen seine Sorgfaltspflichten im Umgang mit den Zugangsdaten versto&szlig;en hat</li>
                                    <li>Gegen anwendbares Recht beim Zugang zu oder der Nutzung der Xylem-Website versto&szlig;en hat</li>
                                    <li>Die Xylem-Website f&uuml;r einen l&auml;ngeren Zeitraum nicht genutzt hat</li>
                                    <li>Bei der Registrierung muss der Nutzer wahrheitsgem&auml;&szlig;e Angaben machen und bei etwaigen sp&auml;teren &Auml;nderungen diese Informationen unverz&uuml;glich (soweit m&ouml;glich online) aktualisieren. Der Nutzer muss gew&auml;hrleisten, dass seine bei Xylem angegebene E-Mail-Adresse g&uuml;ltig ist und er unter dieser Adresse erreicht werden kann.</li>
                                    <li>Bei der Registrierung erh&auml;lt der Nutzer einen Zugangscode, der aus einer Benutzer-ID und einem Kennwort (&bdquo;Benutzerdaten&ldquo;) besteht. Beim ersten Zugriff muss der Nutzer sofort das Kennwort, das er von Xylem erhalten hat, in ein Kennwort &auml;ndern, das nur er kennt. Die Benutzerdaten erm&ouml;glichen dem Nutzer, seine Daten einzusehen, zu &auml;ndern oder ggf. die Einwilligung zur Datenverarbeitung zu widerrufen.</li>
                                    <li>Der Nutzer stellt sicher, dass die Benutzerdaten Dritten nicht zug&auml;nglich sind und haftet f&uuml;r alle unter den Benutzerdaten vorgenommenen Transaktionen und sonstigen Aktivit&auml;ten. Am Ende jeder Online-Sitzung meldet der Nutzer sich von den kennwortgesch&uuml;tzten Websites ab. Sollte der Nutzer erfahren, dass seine Benutzerdaten von Dritten missbraucht werden, so muss der Nutzer Xylem hiervon unverz&uuml;glich schriftlich oder ggf. per E-Mail in Kenntnis setzen. Xylem wird nach Erhalt dieser Mitteilung den Zugang zum kennwortgesch&uuml;tzten Bereich mit diesen Benutzerdaten sperren. Die Aufhebung der Sperre ist erst nach gesondertem Antrag des Nutzers bei Xylem oder nach neuer Registrierung m&ouml;glich.</li>
                                    <li>Der Nutzer kann jederzeit schriftlich die L&ouml;schung seiner Registrierung verlangen, sofern der L&ouml;schung der Abwicklung laufender Vertragsverh&auml;ltnisse nicht entgegensteht. In diesem Fall entfernt Xylem s&auml;mtliche Benutzerdaten und andere gespeicherte personenbezogene Daten des Nutzers, wenn diese Daten nicht mehr ben&ouml;tigt werden.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Informationen und Dokumente</strong></p>
                                <p>&nbsp;</p>
                                <p>Durch die Nutzung der Xylem-Sites bzw. durch Herunterladen von Dokumenten, Material oder Informationen (nachstehend &bdquo;Material&ldquo;) von diesen Websites erkl&auml;ren Sie sich damit einverstanden, an die hier dargelegten rechtlichen Bedingungen gebunden zu sein. Sollten Sie mit diesen rechtlichen Bedingungen nicht einverstanden sein, verlassen Sie die Website umgehend. Die Nutzung und das Herunterladen von Material von dieser oder einer anderen Xylem-Site ist Ihnen dann nicht gestattet.</p>
                                <p>&nbsp;</p>
                                <p><strong>Internationale Nutzer, Rechtswahl, Gerichtsbarkeit</strong></p>
                                <p>&nbsp;</p>
                                <p>Die Xylem-Sites und das darin enthaltene Material unterliegen den Gesetzen des Staates New York in den Vereinigten Staaten von Amerika. Sie erkl&auml;ren sich damit einverstanden, dass die Gesetze des Staates New York das f&uuml;r diese rechtlichen Bedingungen oder das f&uuml;r Ihre Nutzung einer Xylem-Site geltende Recht ist, ungeachtet der Bestimmungen des Kollisionsrechts. Ferner erkl&auml;ren Sie sich damit einverstanden, dass f&uuml;r jegliche Rechtsstreitigkeiten im Zusammenhang mit diesen rechtlichen Bedingungen oder Ihrer Nutzung einer Xylem-Site ausschlie&szlig;lich die Gerichte des Staates New York zust&auml;ndig sind und dass Sie diese Zust&auml;ndigkeit vor keinem anderen Gericht oder Gerichtshof auf der Welt anfechten werden. Xylem &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Angemessenheit oder Eignung der auf den Xylem-Sites enthaltenen Informationen zur Verwendung an Orten au&szlig;erhalb der Vereinigten Staaten. Der Zugriff auf das Material von Gebieten aus, in denen das Material rechtswidrig ist, ist verboten.</p>
                                <p>&nbsp;</p>
                                <p><strong>Websites von Dritten</strong></p>
                                <p>&nbsp;</p>
                                <p>Als Annehmlichkeit f&uuml;r den Nutzer kann Xylem Links zu von anderen betriebenen Websites anbieten. Xylem macht jedoch keine Zusicherung und &uuml;bernimmt keine Gew&auml;hrleistung f&uuml;r verlinkte Websites. Eine Zugeh&ouml;rigkeit oder Verbindung mit diesen Websites wird nicht impliziert. Wenn Sie verlinkte Websites besuchen oder verwenden, geschieht das auf eigene Gefahr.</p>
                                <p>&nbsp;</p>
                                <p><strong>Haftungsausschluss</strong></p>
                                <p>&nbsp;</p>
                                <p>S&auml;mtliches Material, das auf den Xylem-Sites enthalten ist, dort verbreitet wird oder zug&auml;nglich ist, wird ohne M&auml;ngelgew&auml;hr und nach Verf&uuml;gbarkeit bereitgestellt, ohne ausdr&uuml;ckliche oder stillschweigende Gew&auml;hrleistung, einschlie&szlig;lich, jedoch nicht beschr&auml;nkt auf konkludente Gew&auml;hrleistungen im Hinblick auf Handels&uuml;blichkeit, Eignung f&uuml;r einen bestimmten Zweck oder Nichtverletzung von Rechten Dritter. In einigen Rechtssystemen ist der Ausschluss konkludenter Gew&auml;hrleistungen nicht zul&auml;ssig, sodass die oben genannte Ausschlussregelung f&uuml;r Sie ggf. nicht zutrifft.&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>Xylem &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Genauigkeit oder Vollst&auml;ndigkeit des Materials, einschlie&szlig;lich, jedoch nicht beschr&auml;nkt auf die Zuverl&auml;ssigkeit von Ratschl&auml;gen und Aussagen, die auf den Xylem-Sites angezeigt oder bereitgestellt werden. Ihr Vertrauen auf Material, Ratschl&auml;ge, Aussagen oder Informationen unterliegt Ihrer eigenen Verantwortung.</p>
                                <p>&nbsp;</p>
                                <p>Xylem &uuml;bernimmt keine Haftung f&uuml;r eventuelle Sch&auml;den, einschlie&szlig;lich Sch&auml;den durch Viren, die m&ouml;glicherweise Ihren Computer oder sonstiges Eigentum durch Ihren Zugriff auf eine Xylem-Site oder das Herunterladen von Material von diesen Site infiziert haben.</p>
                                <p>&nbsp;</p>
                                <p><strong>Schadensbegrenzung</strong></p>
                                <p>&nbsp;</p>
                                <p>In keinem Fall haftet Xylem f&uuml;r direkte, spezielle, indirekte, Folge- oder Bu&szlig;geldsch&auml;den oder sonstige Sch&auml;den, die aus irgendeinem Grund entstehen, einschlie&szlig;lich, jedoch nicht beschr&auml;nkt auf Nutzungsausf&auml;lle, entgangene Gewinne oder Datenverluste, sei es durch Handlungen im Rahmen des Vertrags, Fahrl&auml;ssigkeit, Gef&auml;hrdungshaftung oder sonstige Handlungen, und die sich aus der Nutzung einer Xylem-Site oder aus der Nutzung von Material, Informationen oder Dokumenten ergeben oder damit zusammenh&auml;ngen, die auf einer Xylem-Site bereitgestellt oder verwendet werden.</p>
                                <p>&nbsp;</p>
                                <p><strong>Beschr&auml;nkte Lizenz</strong></p>
                                <p>&nbsp;</p>
                                <p>Durch Ihr Einverst&auml;ndnis mit den hier dargelegten Bestimmungen: erkl&auml;ren Sie sich damit einverstanden, zu keinem Zweck ohne ausdr&uuml;ckliche schriftliche Genehmigung von Xylem Deep Links oder Frame Links zu einer Xylem-Site zu erstellen. Xylem erteilt Ihnen die Erlaubnis, Material anzuzeigen, herunterzuladen und zu kopieren, sofern (a) alle Hinweise bez&uuml;glich der Urheber- und Eigentumsrechte beibehalten werden, (b) das Material nicht &ouml;ffentlich angezeigt, &ouml;ffentlich verbreitet oder auf irgendeine Weise ge&auml;ndert wird und keine Grafiken unabh&auml;ngig vom zugeh&ouml;rigen Text verwendet werden, und (c) das Material ausschlie&szlig;lich zu informativen und nicht-kommerziellen oder pers&ouml;nlichen Zwecken dient.</p>
                                <p>&nbsp;</p>
                                <p><strong>Vom Nutzer zur Verf&uuml;gung gestellte Informationen</strong></p>
                                <p>&nbsp;</p>
                                <p>Senden Sie keine vertraulichen oder urheberrechtlich gesch&uuml;tzten Informationen &uuml;ber eine Xylem-Site an Xylem. Sie erkl&auml;ren sich damit einverstanden, dass alle von Ihnen gesendeten Informationen nicht als vertraulich oder urheberrechtlich gesch&uuml;tzt gelten, und Sie erteilen Xylem eine uneingeschr&auml;nkte, unwiderrufliche, weltweite, geb&uuml;hrenfreie Lizenz zur Verwendung, Vervielf&auml;ltigung und Verbreitung dieser Informationen.</p>
                                <p>&nbsp;</p>
                                <p><strong>&Auml;nderungen</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem beh&auml;lt sich das Recht vor, diese rechtlichen Bedingungen jederzeit zu &auml;ndern. Derartige &Auml;nderungen oder Modifizierungen werden unverz&uuml;glich wirksam, sobald diese ge&auml;nderten oder modifizierten rechtlichen Bedingungen auf den Xylem-Sites ver&ouml;ffentlicht werden. Bei der Nutzung einer Xylem-Site nach der Ver&ouml;ffentlichung der ge&auml;nderten oder modifizierten rechtlichen Bedingungen wird Ihr Einverst&auml;ndnis mit diesen ge&auml;nderten oder modifizierten Bedingungen vorausgesetzt. Sie sollten die auf den Xylem-Sites ver&ouml;ffentlichten rechtlichen Bedingungen von Zeit zu Zeit durchlesen, um sich mit den aktuellen rechtlichen Bedingungen vertraut zu machen.</p>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else if (getCurrentLng()==="fr") {
            return (
                <div className="sections">
                    <section className="standard-text">
                        <div className="container">
                            <h1 className="title">Conditions générales</h1>
                            <div className="text">
                                <p><strong>Conditions juridiques</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem et ses divisions d&rsquo;exploitation ainsi que ses filiales (d&eacute;sign&eacute;es collectivement &laquo;&nbsp;Xylem&nbsp;&raquo;) maintiennent et g&egrave;rent le pr&eacute;sent mobile app, site web <a href="www.waterdrop.app" aria-invalid="true">www.waterdrop.app</a> et d&rsquo;autres sites web (d&eacute;sign&eacute;s collectivement &laquo;&nbsp;Sites Xylem&nbsp;&raquo;) &agrave; des fins d&rsquo;information uniquement.</p>
                                <p>&nbsp;</p>
                                <p><strong>Enregistrement sur les sites</strong></p>
                                <p>&nbsp;</p>
                                <p>Certaines pages du site&nbsp;Xylem peuvent &ecirc;tre prot&eacute;g&eacute;es par un mot de passe. Aux fins de la s&eacute;curit&eacute; des transactions commerciales, seuls les utilisateurs enregistr&eacute;s peuvent acc&eacute;der auxdites pages. Xylem se r&eacute;serve le droit de refuser l&rsquo;enregistrement d&rsquo;un utilisateur. En particulier, Xylem se r&eacute;serve le droit de rendre n&eacute;cessaire l&rsquo;enregistrement sur certains sites pr&eacute;alablement libres d&rsquo;acc&egrave;s. &Agrave; tout moment et de mani&egrave;re non motiv&eacute;e, Xylem est en droit de refuser &agrave; l&rsquo;utilisateur le droit d&rsquo;acc&egrave;s &agrave; la zone prot&eacute;g&eacute;e par mot de passe en bloquant l&rsquo;utilisateur, en particulier si celui-ci&nbsp;:</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>utilise des donn&eacute;es erron&eacute;es aux fins de l&rsquo;enregistrement ;</li>
                                    <li>enfreint les pr&eacute;sentes conditions g&eacute;n&eacute;rales ;</li>
                                    <li>n&eacute;glige son devoir de prot&eacute;ger ses donn&eacute;es utilisateurs ;</li>
                                    <li>enfreint les lois applicables en acc&eacute;dant &agrave;, ou en utilisant le Site Web Xylem ; ou</li>
                                    <li>n&rsquo;utilise pas le Site Web Xylem pendant une longue p&eacute;riode.</li>
                                    <li>Pour s&rsquo;enregistrer, l&rsquo;utilisateur doit communiquer des informations exactes et, lorsque les informations &eacute;voluent au fil du temps, il doit mettre &agrave; jour lesdites informations (en ligne, dans la mesure du possible) dans les meilleurs d&eacute;lais. L&rsquo;utilisateur doit s&rsquo;assurer que son adresse e-mail fournie &agrave; Xylem est en vigueur &agrave; tout moment et qu&rsquo;il s&rsquo;agit d&rsquo;une adresse &agrave; laquelle l&rsquo;utilisateur peut &ecirc;tre contact&eacute;.</li>
                                    <li>D&egrave;s l&rsquo;enregistrement, l&rsquo;utilisateur recevra un code d&rsquo;acc&egrave;s compos&eacute; d&rsquo;un identifiant utilisateur et d&rsquo;un mot de passe (&laquo;&nbsp;Donn&eacute;es utilisateur&nbsp;&raquo;). Lors du premier acc&egrave;s, l&rsquo;utilisateur modifiera rapidement le mot de passe re&ccedil;u par Xylem par un mot de passe connu par l&rsquo;utilisateur uniquement. Les Donn&eacute;es utilisateur permettent &agrave; l&rsquo;utilisateur de visualiser ou de modifier ses donn&eacute;es ou, le cas &eacute;ch&eacute;ant, de retirer son consentement au traitement des donn&eacute;es.</li>
                                    <li>L&rsquo;utilisateur doit s&rsquo;assurer que les Donn&eacute;es utilisateur ne sont pas accessibles par des tierces parties et est responsable de toutes les transactions ou autres activit&eacute;s effectu&eacute;es &agrave; parti de ses Donn&eacute;es utilisateur. &Agrave; la fin de chaque session en ligne, l&rsquo;utilisateur doit se d&eacute;connecter des sites web prot&eacute;g&eacute;s par mot de passe. Si et dans la mesure o&ugrave; l&rsquo;utilisateur a connaissance du fait que des tierces parties utilisent les Donn&eacute;es utilisateur de mani&egrave;re abusive, l&rsquo;utilisateur doit en informer Xylem sans tarder par &eacute;crit ou, selon le cas, par e-mail. Apr&egrave;s r&eacute;ception de cette notification, Xylem s&rsquo;efforcera de refuser l&rsquo;acc&egrave;s &agrave; la zone prot&eacute;g&eacute;e par mot de passe pour lesdites Donn&eacute;es utilisateur. Le nouvel acc&egrave;s par l&rsquo;utilisateur est uniquement possible par la demande de l&rsquo;utilisateur faite aupr&egrave;s de Xylem ou par un nouvel enregistrement.</li>
                                    <li>L&rsquo;utilisateur peut &agrave; tout moment demander la r&eacute;siliation de l&rsquo;enregistrement par &eacute;crit, &eacute;tant entendu que la suppression ne violera pas la bonne ex&eacute;cution des relations contractuelles. Dans un tel cas, Xylem supprimera toutes les Donn&eacute;es utilisateur et autres donn&eacute;es personnellement identifiables stock&eacute;es de l&rsquo;utilisateur d&egrave;s que ces donn&eacute;es ne sont plus n&eacute;cessaires.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Informations et Documents</strong></p>
                                <p>&nbsp;</p>
                                <p>En utilisant les Sites Xylem et en t&eacute;l&eacute;chargeant des documents, des supports ou des informations (ci-apr&egrave;s d&eacute;sign&eacute;s &laquo;&nbsp;Supports&nbsp;&raquo;) sur ces sites, vous acceptez de vous lier aux Conditions juridiques expos&eacute;es dans la pr&eacute;sente note d&rsquo;information. Si vous ne souhaitez pas &ecirc;tre li&eacute; aux pr&eacute;sentes Conditions juridiques, veuillez quitter imm&eacute;diatement ce site, ne pas utiliser et ne pas t&eacute;l&eacute;charger tout Support provenant de ce site ou de tout autre Site Xylem.</p>
                                <p>&nbsp;</p>
                                <p>Utilisateurs internationaux, Choix de la Loi applicable et de la Juridiction comp&eacute;tente</p>
                                <p>&nbsp;</p>
                                <p>Les Sites Xylem et les Supports qu&rsquo;ils contiennent sont consid&eacute;r&eacute;s comme d&eacute;livr&eacute;s dans l&rsquo;&Eacute;tat de New York aux &Eacute;tats-Unis d&rsquo;Am&eacute;rique et par ce principe, vous acceptez que la loi r&eacute;gissant les pr&eacute;sentes Conditions juridiques ou votre utilisation d&rsquo;un Site Xylem est la loi gouvernant l&rsquo;&Eacute;tat de New York, quelles que soient les r&egrave;gles de conflits de lois applicables. Vous acceptez en outre que tout litige &eacute;manant des pr&eacute;sentes Conditions juridiques ou de votre utilisation d&rsquo;un Site Xylem doit &ecirc;tre soumis exclusivement aux tribunaux de l&rsquo;&Eacute;tat de New York et que vous n&rsquo;affirmerez pas la comp&eacute;tence de tout autre tribunal nulle part dans le monde. Xylem ne donne aucune garantie quant au fait que les Supports sur les Sites Xylem sont ad&eacute;quats ou disponibles pour une utilisation dans des lieux en dehors des &Eacute;tats-Unis. L&rsquo;acc&egrave;s aux Supports &agrave; partir des territoires o&ugrave; les Supports sont de nature ill&eacute;gale est interdit.</p>
                                <p>&nbsp;</p>
                                <p><strong>Sites de tierces parties</strong></p>
                                <p>&nbsp;</p>
                                <p>&Agrave; titre indicatif seulement, Xylem peut fournir des liens vers des sites web g&eacute;r&eacute;s par d&rsquo;autres entit&eacute;s, mais n&rsquo;offre aucune garantie, ni ne fait aucune d&eacute;claration de quelque nature que ce soit sur tout site li&eacute; et n&rsquo;implique pas une affiliation ou une association avec ledit site. Si vous visitez ou utilisez un site li&eacute;, vous le faites &agrave; vos propres risques.</p>
                                <p>&nbsp;</p>
                                <p><strong>D&eacute;charge de responsabilit&eacute;</strong></p>
                                <p>&nbsp;</p>
                                <p>Toutes les informations sur, distribu&eacute;es par ou accessibles depuis les Sites Xylem sont fournies &laquo;&nbsp;telles quelles&nbsp;&raquo; et &laquo;&nbsp;telles que disponibles&nbsp;&raquo;, sans garantie de quelque sorte que ce soit, expresse ou implicite, y compris, de mani&egrave;re non limitative, les garanties implicites de commercialisation, d&rsquo;ad&eacute;quation &agrave; un but particulier ou de non-violation. Cependant, si la l&eacute;gislation de votre pays n&rsquo;autorise pas l&rsquo;exclusion de ces garanties tacites, le paragraphe pr&eacute;c&eacute;dent ne s&rsquo;applique pas.</p>
                                <p>&nbsp;</p>
                                <p>Xylem ne garantit pas l&rsquo;exactitude ou l&rsquo;exhaustivit&eacute; des supports y compris, de mani&egrave;re non limitative, en ce qui concerne la fiabilit&eacute; de toute d&eacute;claration ou de tout conseil affich&eacute; ou distribu&eacute; par les Sites Xylem et vous faites confiance &agrave; de tels supports, conseils, d&eacute;clarations ou informations &agrave; vos propres risques.</p>
                                <p>&nbsp;</p>
                                <p>Xylem ne sera pas responsable des dommages, y compris des dommages provenant de virus qui peuvent infecter votre &eacute;quipement informatique ou tout bien en vertu de votre acc&egrave;s au Site Xylem ou de tout t&eacute;l&eacute;chargement de tous supports depuis ce site.</p>
                                <p>&nbsp;</p>
                                <p><strong>Limitation des dommages</strong></p>
                                <p>&nbsp;</p>
                                <p>En aucun cas, Xylem ne sera responsable des dommages directs, sp&eacute;ciaux, indirects, cons&eacute;cutifs, punitifs ou autres, de quelque sorte que ce soit, r&eacute;sultant de quelque cause que ce soit, y compris, de mani&egrave;re non limitative, de la perte de profits, du manque &agrave; gagner ou de la perte de donn&eacute;es que ce soit dans une action selon les th&eacute;ories juridiques, la n&eacute;gligence ou de la stricte responsabilit&eacute; ou d&rsquo;une autre mani&egrave;re, &eacute;manant de, ou d&rsquo;une quelconque mani&egrave;re en relation avec l&rsquo;utilisation du Site Xylem ou de tous supports, toutes informations ou tous documents pr&eacute;sents sur ou accessibles par le biais de tout Site Xylem.</p>
                                <p>&nbsp;</p>
                                <p><strong>Licence limit&eacute;e</strong></p>
                                <p>&nbsp;</p>
                                <p>En acceptant les Conditions juridiques contenues dans les pr&eacute;sentes pages&nbsp;: vous acceptez de ne pas utiliser de lien profond &laquo;&nbsp;deep link&nbsp;&raquo; ou de lien encart &laquo;&nbsp;frame link&nbsp;&raquo; vers tout Site Xylem &agrave; toute fin sans l&rsquo;autorisation expresse &eacute;crite de Xylem&nbsp;; et Xylem vous accorde l&rsquo;autorisation de visualiser, t&eacute;l&eacute;charger et copier les Supports, &eacute;tant entendu que (a)&nbsp;les avis de droit d&rsquo;auteur et de propri&eacute;t&eacute; sont conserv&eacute;s, (b)&nbsp;les Supports ne sont pas publiquement affich&eacute;s, publiquement distribu&eacute;s, ou modifi&eacute;s de quelque mani&egrave;re que ce soit et qu&rsquo;aucun &eacute;l&eacute;ment graphique n&rsquo;est utilis&eacute; s&eacute;par&eacute;ment de son texte d&rsquo;accompagnement, et (c)&nbsp;toute utilisation des Supports est effectu&eacute;e &agrave; des fins d&rsquo;information, non-commerciales et personnelles uniquement.</p>
                                <p>&nbsp;</p>
                                <p><strong>Informations que vous fournissez</strong></p>
                                <p>&nbsp;</p>
                                <p>N&rsquo;envoyez pas d&rsquo;informations confidentielles ou exclusives &agrave; Xylem via un Site Xylem. Vous acceptez que toute information que vous envoyez ainsi ne soit pas consid&eacute;r&eacute;e comme confidentielle ou exclusive et vous octroyez &agrave; Xylem une licence non restrictive, irr&eacute;vocable, mondiale et libre de redevance relative &agrave; l&rsquo;utilisation, la reproduction et la distribution de toute information de la sorte.</p>
                                <p>&nbsp;</p>
                                <p><strong>Modifications</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem se r&eacute;serve le droit de modifier les pr&eacute;sentes Conditions juridiques &agrave; tout moment. Toute modification sera imm&eacute;diatement en vigueur lorsque lesdites Conditions juridiques modifi&eacute;es sont publi&eacute;es sur les Sites Xylem. Votre utilisation d&rsquo;un Site Xylem apr&egrave;s la publication de la modification des Conditions juridiques repr&eacute;sente votre acceptation desdites Conditions juridiques modifi&eacute;es. Vous consulterez de temps &agrave; autre les Conditions juridiques publi&eacute;es sur les Sites Xylem pour prendre connaissance des Conditions juridiques alors en vigueur.</p>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else if (getCurrentLng()==="sv") {
            return (
                <div className="sections">
                    <section className="standard-text">
                        <div className="container">
                            <h1 className="title">Regler och villkor</h1>
                            <div className="text">
                                <p><strong>Juridiska best&auml;mmelser</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem och dess aff&auml;rsomr&aring;den och dotterf&ouml;retag (gemensamt &rdquo;Xylem&rdquo;) underh&aring;ller och driver denna och andra webbplatser (gemensamt &rdquo;Xylems webbplats&rdquo;) endast i informationssyfte.</p>
                                <p>&nbsp;</p>
                                <p><strong>Registrering f&ouml;r webbplatser</strong></p>
                                <p>Vissa sidor p&aring; Xylems webbplats kan vara l&ouml;senordsskyddade. I skydds- och s&auml;kerhetsintresse f&ouml;r aff&auml;rstransaktionerna har endast registrerade anv&auml;ndare tillg&aring;ng till dessa sidor. Xylem f&ouml;rbeh&aring;ller sig r&auml;tten att neka registrering av anv&auml;ndare. Xylem f&ouml;rbeh&aring;ller sig speciellt r&auml;tten att besluta att vissa webbplatser, som tidigare varit fritt tillg&auml;ngliga, ska kr&auml;va registrering. Xylem har r&auml;tt att n&auml;r som helst och utan skyldighet att ange orsak neka en anv&auml;ndare r&auml;tt att f&aring; tillg&aring;ng till det l&ouml;senordsskyddade omr&aring;det genom att blockera anv&auml;ndaren, speciellt om anv&auml;ndaren:</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>Anv&auml;nder falska uppgifter f&ouml;r registreringen</li>
                                    <li>Bryter mot dessa villkor</li>
                                    <li>F&ouml;rsummar ansvaret att behandla personuppgifter med f&ouml;rsiktighet</li>
                                    <li>&Ouml;vertr&auml;der eventuella till&auml;mpliga lagar vid &aring;tkomsten till eller anv&auml;ndningen av Xylems webbplats</li>
                                    <li>Inte har anv&auml;nt Xylems webbplats p&aring; l&aring;ng tid</li>
                                    <li>F&ouml;r registrering ska anv&auml;ndaren uppge exakt information, och d&auml;r s&aring;dan information &auml;ndras &ouml;ver tiden uppdatera informationen (online i den utstr&auml;ckning det &auml;r m&ouml;jligt) utan otillb&ouml;rligt f&ouml;rdr&ouml;jning. Anv&auml;ndaren ska s&auml;kerst&auml;lla att e-postadressen, s&aring; som den &auml;r uppgiven till Xylem, alltid &auml;r aktuell och &auml;r en adress som anv&auml;ndaren kan n&aring;s p&aring;.</li>
                                    <li>Vid registreringen f&aring;r anv&auml;ndaren en tillg&aring;ngskod best&aring;ende av ett anv&auml;ndar-ID och ett l&ouml;senord (&rdquo;Anv&auml;ndardata&rdquo;). Vid den f&ouml;rsta inloggningen ska anv&auml;ndaren genast &auml;ndra det l&ouml;senord som mottagits fr&aring;n Xylem till ett l&ouml;senord som bara anv&auml;ndaren k&auml;nner till. Anv&auml;ndardata medger att anv&auml;ndaren kan se och &auml;ndra sina uppgifter eller, om det &auml;r aktuellt, &aring;terta sitt medgivande till att uppgifterna behandlas.</li>
                                    <li>Anv&auml;ndaren ska s&auml;kerst&auml;lla att Anv&auml;ndardata inte &auml;r tillg&auml;ngliga f&ouml;r tredje part och &auml;r ansvarig f&ouml;r alla transaktioner eller andra aktiviteter som utf&ouml;rts under anv&auml;ndarens Anv&auml;ndardata. Vid slutet av varje onlinesession ska anv&auml;ndaren logga ut fr&aring;n l&ouml;senordsskyddade sidor. Om och i den utstr&auml;ckning anv&auml;ndaren blir medveten om att tredje part missbrukar anv&auml;ndarens Anv&auml;ndardata, ska anv&auml;ndaren underr&auml;tta Xylem om detta utan otillb&ouml;rlig f&ouml;rdr&ouml;jning skriftligt eller i f&ouml;rekommande fall via e-post. Efter mottagande av denna anm&auml;lan kommer Xylem att f&ouml;rs&ouml;ka neka tillg&aring;ng till det l&ouml;senordsskyddade omr&aring;det under dessa Anv&auml;ndardata. Anv&auml;ndaren kan endast f&aring; tillg&aring;ng igen genom att v&auml;nda sig till Xylem eller registrera sig p&aring; nytt.</li>
                                    <li>Anv&auml;ndaren kan n&auml;r som helst skriftligt beg&auml;ra att registreringen upph&ouml;r, s&aring;vida inte avslutet strider mot korrekt genomf&ouml;rande av avtalsenliga f&ouml;rh&aring;llanden. I ett s&aring;dant fall kommer Xylem att radera alla Anv&auml;ndardata och andra sparade, personligt identifierbara uppgifter om anv&auml;ndaren s&aring; snart dessa uppgifter inte l&auml;ngre beh&ouml;vs.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Information och dokument</strong></p>
                                <p>&nbsp;</p>
                                <p>Genom att anv&auml;nda Xylems webbplats eller ladda ned dokument, material eller information (h&auml;danefter &rdquo;Material&rdquo;) d&auml;rifr&aring;n godk&auml;nner du att vara bunden av de juridiska best&auml;mmelser som presenteras h&auml;r. Om du inte &ouml;nskar bli bunden av dessa juridiska best&auml;mmelser ska du omedelbart l&auml;mna, inte anv&auml;nda och inte ladda ned n&aring;got Material fr&aring;n denna webbplats eller n&aring;gon annan av Xylems webbplatser.</p>
                                <p>&nbsp;</p>
                                <p><strong>Internationella anv&auml;ndare, val av lagstiftning och jurisdiktion</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylems webbplats och det Material den inneh&aring;ller ska anses ha skapats i staten New York i USA, och du godk&auml;nner att de lagar som styr dessa juridiska best&auml;mmelser eller din anv&auml;ndning av Xylems webbplats ska vara staten New Yorks lagar utan h&auml;nsyn till eventuella principer eller lagkonflikter. Du godk&auml;nner ocks&aring; att eventuella tvister som uppkommer under dessa juridiska best&auml;mmelser eller fr&aring;n din anv&auml;ndning av Xylems webbplats endast ska avg&ouml;ras av domstolarna i staten New York, och att du inte kommer att h&auml;vda r&auml;ttsskipning i n&aring;gon annan domstol n&aring;gonstans i v&auml;rlden. Xylem p&aring;st&aring;r inte att Materialet p&aring; Xylems webbplats &auml;r l&auml;mpligt eller tillg&auml;ngligt f&ouml;r anv&auml;ndning p&aring; platser utanf&ouml;r USA. Tillg&aring;ng till Materialet fr&aring;n omr&aring;den d&auml;r Materialet &auml;r olagligt &auml;r f&ouml;rbjudet.</p>
                                <p>&nbsp;</p>
                                <p><strong>Tredje parts webbplatser</strong></p>
                                <p>&nbsp;</p>
                                <p>F&ouml;r att underl&auml;tta f&ouml;r dig kan Xylem tillhandah&aring;lla l&auml;nkar till webbplatser som hanteras av andra organisationer, men Xylem ger inga garantier och uttalar sig inte om n&aring;gonting som avser en l&auml;nkad sida och har inte heller n&aring;gon underf&ouml;rst&aring;dd anknytning till n&aring;gon s&aring;dan sida. Om du bes&ouml;ker eller anv&auml;nder en l&auml;nkad sida g&ouml;r du det p&aring; egen risk.</p>
                                <p>&nbsp;</p>
                                <p><strong>Friskrivning</strong></p>
                                <p>&nbsp;</p>
                                <p>Allt material p&aring;, distribuerat genom eller tillg&auml;ngligt fr&aring;n Xylems webbplats tillhandah&aring;lls &rdquo;som det ser ut&rdquo; utan n&aring;gra garantier, uttryckta eller underf&ouml;rst&aring;dda inklusive utan begr&auml;nsning underf&ouml;rst&aring;dda garantier eller villkor f&ouml;r s&auml;ljbarhet, l&auml;mplighet f&ouml;r ett visst &auml;ndam&aring;l eller icke-intr&aring;ng. Viss lagstiftning till&aring;ter inte att underf&ouml;rst&aring;dda garantier utesluts, s&aring; det kan t&auml;nkas att ovanst&aring;ende undantag inte g&auml;ller dig.</p>
                                <p>&nbsp;</p>
                                <p>Xylem garanterar inte och g&ouml;r inga utf&auml;stelser om riktigheten av materialet inklusive utan begr&auml;nsning trov&auml;rdigheten i eventuella r&aring;d eller p&aring;st&aring;enden som visas p&aring; eller distribueras genom Xylems webbplats, och ditt f&ouml;rtroende f&ouml;r s&aring;dant material, r&aring;d, p&aring;st&aring;ende eller information &auml;r helt och h&aring;llet p&aring; din egen risk.</p>
                                <p>&nbsp;</p>
                                <p>Xylem ska inte h&aring;llas ansvarigt f&ouml;r eventuella skador av virus som kan smitta din datorutrustning eller annan egendom till f&ouml;ljd av att du har bes&ouml;kt Xylems webbplats eller laddat ned n&aring;got material fr&aring;n den.</p>
                                <p>&nbsp;</p>
                                <p><strong>Begr&auml;nsning av skadeansvar</strong></p>
                                <p>&nbsp;</p>
                                <p>Under inga omst&auml;ndigheter ska Xylem vara ansvarigt f&ouml;r direkta skador, speciella skador, indirekta skador, f&ouml;ljdskador, straffp&aring;f&ouml;ljder eller andra skador av n&aring;got slag, oberoende av orsak, inklusive utan begr&auml;nsning avbrott i verksamheten, f&ouml;rlust av vinst eller data vare sig det sker under avtal, genom f&ouml;rsumlighet eller strikt ansvar eller p&aring; annat s&auml;tt h&auml;rr&ouml;r fr&aring;n eller p&aring; n&aring;got s&auml;tt har samband med anv&auml;ndningen av Xylems webbplats eller annat material, information eller dokument som finns p&aring; Xylems webbplats eller har n&aring;tts via denna.</p>
                                <p>&nbsp;</p>
                                <p><strong>Licensbegr&auml;nsning</strong></p>
                                <p>&nbsp;</p>
                                <p>Genom att godk&auml;nna dessa juridiska best&auml;mmelser samtycker du till att inte l&auml;nka till Xylems webbplats i n&aring;got syfte utan skriftligt tillst&aring;nd fr&aring;n Xylem. Xylem ger dig tillst&aring;nd att se, ladda ned och kopiera Material f&ouml;rutsatt att (a) alla upphovsr&auml;tter och &auml;gander&auml;ttsinformation bibeh&aring;lls, (b) Materialet inte visas eller distribueras offentligt eller modifieras p&aring; n&aring;got s&auml;tt och att inga bilder anv&auml;nds separat fr&aring;n den tillh&ouml;rande texten samt (c) all anv&auml;ndning av Materialet endast &auml;r f&ouml;r information och icke-kommersiellt eller personligt bruk.</p>
                                <p>&nbsp;</p>
                                <p><strong>Information som du l&auml;mnar</strong></p>
                                <p>&nbsp;</p>
                                <p>Skicka inte konfidentiell eller personlig information till Xylem via Xylems webbplats. Du samtycker till att all information du skickar inte betraktas som konfidentiell eller personlig, och du ger Xylem en obegr&auml;nsad, ej &aring;terkallerlig, global, royaltyfri licens att anv&auml;nda, reproducera och distribuera s&aring;dan information.</p>
                                <p>&nbsp;</p>
                                <p><strong>&Auml;ndringar</strong></p>
                                <p>&nbsp;</p>
                                <p>Xylem f&ouml;rbeh&aring;ller sig r&auml;tten att n&auml;r som helst &auml;ndra dessa juridiska best&auml;mmelser. S&aring;dana &auml;ndringar eller modifieringar tr&auml;der i kraft omedelbart n&auml;r s&aring;dana &auml;ndrade eller modifierade juridiska best&auml;mmelser publiceras p&aring; Xylems webbplats. N&auml;r du anv&auml;nder Xylems webbplats efter det att modifierade eller &auml;ndrade juridiska best&auml;mmelser har publicerats inneb&auml;r det att du godk&auml;nner de modifierade eller &auml;ndrade juridiska best&auml;mmelserna. Du b&ouml;r d&aring; och d&aring; l&auml;sa de juridiska best&auml;mmelser som finns p&aring; Xylems webbplats f&ouml;r att se den aktuella versionen.</p>
                            </div>
                        </div>
                    </section>
                </div>
            )

        }
    }
}

export default TermsConditions;
