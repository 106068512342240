import React, {Suspense} from "react";
import {Switch, Route, NavLink, Link, BrowserRouter} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/Home";
import About from "./components/About";
import FAQ from "./components/FAQ";
import WaterTower from "./components/WaterTower";
import Peoples from "./components/Peoples";
import PeopleSingle from "./components/PeopleSingle";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import TermsOfService from "./components/TermsOfService";
import DownloadApp from "./components/DownloadApp";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
// const { t, i18n } = useTranslation('common');
// import Stuff from "./Stuff";
// import Contact from "./Contact";
//                                    <li className="join">
//                                         <Link to="#">Join us</Link>
//                                     </li>
//                                     <li className="login">
//                                     </li>

//
//                                         <Link to="">Login</Link>
const languages_names = {
    en : "English",
    de : "Deutsch",
    fr : "Français",
    sv : "Svenska",
    // it : "Italiano",
};
function Page() {
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng, (err, t) => {
            let switchers = document.getElementsByClassName('lang-sw');
            for(let i = 0; i < switchers.length; i++) {
                switchers[i].classList.remove('hide');
            }
            let switchers_cur_lang = document.getElementsByClassName('lang-sw lang-'+lng);
            for(let i = 0; i < switchers_cur_lang.length; i++) {
                switchers_cur_lang[i].classList.add('hide');
            }
        });
    };
    var currentLang = i18next.language;
    var menuClicked = false;
    function showMenu() {
        menuClicked = true;
        document.getElementsByTagName("BODY")[0].classList.add('show-menu')
    }
    function hideMenu(event) {
        menuClicked = false;
        document.getElementsByTagName("BODY")[0].classList.remove('show-menu');
        if (event.currentTarget.classList.contains('parent-link')) {
            event.currentTarget.classList.toggle('show-submenu');
            // toggleSubmenu();
        } else {
            let parent_links = document.getElementsByClassName('parent-link');
            for(let i = 0; i < parent_links.length; i++) {
                parent_links[i].classList.remove('show-submenu');
            }
            // removeSubmenu();
        }
    }
    // function toggleSubmenu() {
    //     document.getElementsByTagName("BODY")[0].classList.toggle('show-submenu');
    // }
    // function removeSubmenu() {
    //     document.getElementsByTagName("BODY")[0].classList.remove('show-submenu');
    // }
    function toggleMenu() {
        menuClicked = !menuClicked;
        if (menuClicked) {
            showMenu();
        } else {
            hideMenu();
        }
    }
    // render() {
        return (
            <BrowserRouter>
                <ScrollToTop/>
                <div id="container">
                    <nav id="nav">
                        <div className="container">
                            <div className="bg">
                                <div>
                                </div>
                            </div>
                            <Link className="logo" to={`${process.env.PUBLIC_URL}/`}>
                                <img src={require('./assets/waterdrop_logo.svg')} alt="waterdrop"/>
                            </Link>
                            <div id="menu-container">
                                <ul className="menu">
                                    <li>
                                        <NavLink to={`${process.env.PUBLIC_URL}/`} onClick={hideMenu}>
                                            { t('Home') }
                                        </NavLink>
                                    </li>
                                    <li className="with-submenu">
                                        <NavLink to={`${process.env.PUBLIC_URL}/program/about`} onClick={hideMenu} className="parent-link">
                                            { t('Programme') }
                                            <span className="arr"></span>
                                        </NavLink>
                                        <ul>
                                            <li>
                                                <NavLink to={`${process.env.PUBLIC_URL}/program/about`} onClick={hideMenu}>
                                                    { t('About') }
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${process.env.PUBLIC_URL}/program/faq`} onClick={hideMenu}>
                                                    { t('Faq') }
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${process.env.PUBLIC_URL}/program/water-tower`} onClick={hideMenu}>
                                                    { t('Water towers') }
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${process.env.PUBLIC_URL}/program/tos`} onClick={hideMenu}>
                                                    { t('Terms of Service') }
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to={`${process.env.PUBLIC_URL}/peoples`} onClick={hideMenu}>
                                            { t('People') }
                                        </NavLink>
                                    </li>
                                    <li className="with-submenu">
                                        <span className="parent-link menu-el" id="current-language" onClick={hideMenu}>{languages_names[currentLang]}<span className="arr"></span></span>
                                        <ul>
                                            {
                                                Object.keys(languages_names).map(key =>
                                                    <li className={`lang-sw lang-${key}${key===currentLang ? ' hide' : ''}`} onClick={hideMenu}>
                                                        <span className={`menu-el el-click`} onClick={() => changeLanguage(key)}>{languages_names[key]}</span>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                            <div id="menu-btn" onClick={toggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </nav>
                    <main>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/program/about`} component={About}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/program/faq`} component={FAQ}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/program/water-tower`} component={WaterTower}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/peoples`} component={Peoples}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/peoples/:peopleId`} component={PeopleSingle}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsConditions}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/program/tos`} component={TermsOfService}/>,
                            <Route exact path={`${process.env.PUBLIC_URL}/download-app`} component={DownloadApp}/>,
                        </Switch>
                    </main>
                    <footer id="footer">
                        <div className="container">
                            <div className="disclaimer">
                                <p>
                                    <strong dangerouslySetInnerHTML={{__html: t('Disclaimer')}}></strong>
                                </p>
                                <p>&nbsp;</p>
                                <p dangerouslySetInnerHTML={{__html: t('Disclaimer Xylem Europe')}}></p>
                            </div>
                            <div className="social-links">
                                <strong dangerouslySetInnerHTML={{__html: t('Footer - social')}}></strong>
                                <a href="https://www.instagram.com/xylem_inc/" target="_blank" rel="noopener noreferrer"><img src={require('./assets/instagram.png')} alt="instagram"/></a>
                                <a href="https://www.facebook.com/XylemIncorporated/" target="_blank" rel="noopener noreferrer"><img src={require('./assets/facebook.png')} alt="facebook"/></a>
                                <a href="https://twitter.com/xyleminc" target="_blank" rel="noopener noreferrer"><img src={require('./assets/twitter.png')} alt="twitter"/></a>
                            </div>
                            {/*<div className="lang-switcher">*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <span onClick={() => changeLanguage('en')}>English</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <span onClick={() => changeLanguage('de')}>German</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <span onClick={() => changeLanguage('fr')}>French</span>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <div className={`clr`}></div>
                            <div className="bottom">
                                <div dangerouslySetInnerHTML={{__html: t('Responsible contact')}}></div>
                                <div>
                                    &copy; 2018 Xylem inc. <NavLink to={`${process.env.PUBLIC_URL}/privacy-policy`} dangerouslySetInnerHTML={{__html: t('Privacy Policy')}}></NavLink> | <NavLink to={`${process.env.PUBLIC_URL}/terms-and-conditions`} dangerouslySetInnerHTML={{__html: t('Terms and conditions')}}></NavLink> | <NavLink to={`${process.env.PUBLIC_URL}/program/tos`} dangerouslySetInnerHTML={{__html: t('Terms of Service')}}></NavLink>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: t('VAT ID')}}></div>
                            </div>
                        </div>
                    </footer>
                </div>
            </BrowserRouter>

        );
    // }
}
//todo stylowanie
const Loader = () => (
    <div className="loader">
        <img src={require('./assets/waterdrop_logo.svg')} alt="xylem waterdrop" />
        <div>loading...</div>
    </div>
);

export default function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
    );
}
