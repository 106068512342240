import React from "react";
// import {useTranslation} from "react-i18next";
// import json_content from "../assets/people/content.json";
import 'simple-lightbox/dist/simpleLightbox.min.css'
import i18next from "i18next";
import peoples_en from "../translations/peoples-en";
import peoples_fr from "../translations/peoples-fr";
import peoples_de from "../translations/peoples-de";
import peoples_sv from "../translations/peoples-sv";
import peoples_it from "../translations/peoples-it";
import { useTranslation } from 'react-i18next';
var people_desc = peoples_en;
// var req = require.context('../assets');
var peopleId = '';
var SimpleLightbox = require('simple-lightbox');
class CustomComponent extends React.Component {
    constructor(props) {
        super(props);
        peopleId = props.match.params.peopleId;
    }
    componentDidMount() {
        new SimpleLightbox({elements: '.gallery .imgs a'});

        // $(document).ready(function () {
        //     let gallery = $('.gallery a');
        //     if (gallery.length > 0) {
        //         gallery.simpleLightbox();
        //     }
        // });
    }
    render() {
        return (
            <PeopleSinglePage />
        );
    }
}
export default CustomComponent;

function PeopleSinglePage() {
    const { t } = useTranslation();
    //todo tmp
    var curr_lang = i18next.language;
    if (curr_lang==="en") {
        people_desc = peoples_en;
    } else if (curr_lang==="de") {
        people_desc = peoples_de;
    } else if (curr_lang==="fr") {
        people_desc = peoples_fr;
    } else if (curr_lang==="sv") {
        people_desc = peoples_sv;
    } else if (curr_lang==="it") {
        people_desc = peoples_it;
    }

    if (typeof people_desc[peopleId].videos_youtube!=="undefined") {
        var videos_youtube_obj = people_desc[peopleId].videos_youtube;
        var videos_youtube_ids = [];
        for (var key in videos_youtube_obj) {
            if (!videos_youtube_obj.hasOwnProperty(key)) continue;
            var obj = videos_youtube_obj[key];
            for (var prop in obj) {
                if (!obj.hasOwnProperty(prop)) continue;
                if (prop==="link") {
                    var video_id = '';
                    var video_url = obj[prop];
                    if (video_url.indexOf('watch?v=')>0) {
                        video_id = video_url.slice(video_url.indexOf('watch?v=')+8);
                        if (video_id.indexOf('&')>0) {
                            video_id = video_id.slice(0,video_id.indexOf('&'));
                        }
                    } else if (video_url.indexOf('//youtu.be/')>0) {
                        video_id = video_url.slice(video_url.indexOf('//youtu.be/')+11);
                        if (video_id.indexOf('?')>0) {
                            video_id = video_id.slice(0,video_id.indexOf('?'));
                        }
                    }
                    if (video_id!=="") {
                        videos_youtube_ids.push(video_id);
                    }
                }
            }
        }
    }


    if (typeof people_desc[peopleId]!=="undefined") {
        return (
            <div className="sections">
                <section className="people-single" style={{backgroundImage: `url(`+(people_desc[peopleId].img)+`)`}}>
                    <div className="container">
                        <div className="text">
                            <div className="content">
                                <div className="people-details">
                                    <img className="people-avatar" src={(people_desc[peopleId].author.img)} alt=""/>
                                    <h2 className="people-name">{people_desc[peopleId].author.name}</h2>
                                    <h4 className="people-position">{people_desc[peopleId].author.position}</h4>
                                    <div className="tags">
                                        {/*<span className="country ph">{people_desc[peopleId].country.name}</span>*/}
                                        <span className={`year active year-${people_desc[peopleId].year}`}>{people_desc[peopleId].year}</span>
                                    </div>
                                    <h3 className="title">{people_desc[peopleId].title}</h3>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: people_desc[peopleId].content}} />
                                {typeof people_desc[peopleId].videos!=="undefined" ? (
                                    <div className="videos">
                                        {Object.keys(people_desc[peopleId].videos).map(itm => people_desc[peopleId].videos[itm]).map((value) => (
                                            <video key={value.src} controls poster={value.poster}>
                                                <source src={value.src} type={'video/mp4'}/>
                                            </video>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="empty"></div>
                                )}
                                {typeof videos_youtube_ids!=="undefined" ? (
                                    <div className="videos-youtube">
                                        {videos_youtube_ids.map((value) => (
                                            <div className="video-el">
                                                <iframe title="youtube" width="1920" height="1080" src={`https://www.youtube-nocookie.com/embed/`+value+`?controls=1&rel=0&showinfo=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="empty"></div>
                                )}
                                {typeof people_desc[peopleId].gallery!=="undefined" ? (
                                    <div className="gallery">
                                        <span className="subtitle">Media</span>
                                        <div className="imgs">
                                            {
                                                Object.keys(people_desc[peopleId].gallery).map(itm => people_desc[peopleId].gallery[itm]).map((value) => (
                                                <a key={value.thumb} href={(value.img)} data-lightbox="media">
                                                    <img src={(value.thumb)} alt=""/>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="empty"></div>
                                )}
                            </div>
                            <div className="share-it">
                                <span dangerouslySetInnerHTML={{__html: t('Share this story')}}></span>
                                <a href="https://www.instagram.com/xylem_inc/" target="_blank" rel="noopener noreferrer"><img src={require('../assets/instagram-2.png')} alt="instagram"/></a>
                                <a href="https://www.facebook.com/XylemIncorporated/" target="_blank" rel="noopener noreferrer"><img src={require('../assets/facebook-2.png')} alt="facebook"/></a>
                                <a href="https://twitter.com/xyleminc" target="_blank" rel="noopener noreferrer"><img src={require('../assets/twitter-2.png')} alt="twitter"/></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    } else {
        return (
            <div className="sections">
                <section className="people-single">
                    <div className="container">
                        <div className="text">
                            <div className="content">
                                Nothing found
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


// export default function PeopleSingle() {
//     return (
//         <PeopleSinglePage />
//     );
// }
