import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class DownloadApp extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="sections no-bottom">
                <section className="intro-2">
                    <div className="container">
                        <div className="container-in">
                            <div className="img">
                                <img src={require('../assets/phone-app.jpg')} alt="Phone app"/>
                            </div>
                            <div className="text">
                                <p><strong dangerouslySetInnerHTML={{__html: t('No app - header')}}></strong></p>
                                <p>&nbsp;</p>
                                <p dangerouslySetInnerHTML={{__html: t('No app - text')}}></p>
                                <div className="links">
                                    <a target="_blank" rel="noopener noreferrer" href={t('App Store link')}><img src={require('../assets/app-store.png')} alt="App Store"/></a>
                                    <a target="_blank" rel="noopener noreferrer" href={t('Google Play link')}><img src={require('../assets/google-play.png')} alt="Google Play"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withTranslation()(DownloadApp);
