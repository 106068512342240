import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import peoples_en from "../translations/peoples-en";
import peoples_fr from "../translations/peoples-fr";
import peoples_de from "../translations/peoples-de";
import peoples_sv from "../translations/peoples-sv";
import peoples_it from "../translations/peoples-it";
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

var fullContent = peoples_en;
var years = {};
var first_content_key;
var first_content;
var latestContent;
// var req = require.context('../assets');
// import fullContent from "../assets/people/content";
// var countries = {};
// for (const key in fullContent) {
//     if (fullContent.hasOwnProperty(key)) {
//         if (typeof fullContent[key].country!=="undefined") {
//             countries[fullContent[key].country.code] = fullContent[key].country.name;
//         }
//     }
// }

class Peoples extends Component {
    constructor() {
        super();
        this.refreshLanguageVariables();
        this.state = {
            searchResults : latestContent,
            searchStr : '',
            isSearch : false,
            // country_clicked: []
            years_clicked: []
        };


        this.handleInput = this.handleInput.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }
    refreshLanguageVariables() {
        //todo tmp
        var curr_lang = i18next.language;
        if (curr_lang==="en") {
            fullContent = peoples_en;
        } else if (curr_lang==="de") {
            fullContent = peoples_de;
        } else if (curr_lang==="fr") {
            fullContent = peoples_fr;
        } else if (curr_lang==="sv") {
            fullContent = peoples_sv;
        } else if (curr_lang==="it") {
            fullContent = peoples_it;
        }

        years = {};
        first_content_key = Object.keys(fullContent)[0];
        first_content = fullContent[first_content_key];
        latestContent = Object.assign({}, fullContent);
        delete latestContent[first_content_key];
        for (const key in fullContent) {
            if (fullContent.hasOwnProperty(key)) {
                if (typeof fullContent[key].year!=="undefined") {
                    years[fullContent[key].year] = fullContent[key].year.toString();
                }
            }
        }
    }
    handleInput(event) {
        if (typeof event.target.value!=="undefined") {
            this.setState({ searchStr: event.target.value }, ()=>this.handleFilter());
        }
    }
    handleFilter() {
        let tmpResults = {};
        // let tmp_countries = this.state.country_clicked;
        let tmp_years = this.state.years_clicked;
        if( this.state.searchStr.length > 2){
            this.setState({isSearch : true});
            // this.state.searchResults = {};
            let searchString = this.state.searchStr.toString().toLowerCase().trim();
            // Object.keys(fullContent).map(key => {
            //     if (fullContent[key].intro.toLowerCase().indexOf(searchString) !== -1
            //         || fullContent[key].title.toString().toLowerCase().indexOf(searchString) !== -1
            //         || fullContent[key].content.toString().toLowerCase().indexOf(searchString) !== -1
            //         // || fullContent[key].country.name.toString().toLowerCase().indexOf(searchString) !== -1
            //         || fullContent[key].author.name.toString().toLowerCase().indexOf(searchString) !== -1
            //         || fullContent[key].year.toString().toLowerCase().indexOf(searchString) !== -1
            //     ) {
            //         if (typeof tmpResults[key]==="undefined") {
            //             tmpResults[key] = fullContent[key];
            //         }
            //     }
            // });
            Object.keys(fullContent).forEach(function(key) {
                if (fullContent[key].intro.toLowerCase().indexOf(searchString) !== -1
                    || fullContent[key].title.toString().toLowerCase().indexOf(searchString) !== -1
                    || fullContent[key].content.toString().toLowerCase().indexOf(searchString) !== -1
                    // || fullContent[key].country.name.toString().toLowerCase().indexOf(searchString) !== -1
                    || fullContent[key].author.name.toString().toLowerCase().indexOf(searchString) !== -1
                    || fullContent[key].year.toString().toLowerCase().indexOf(searchString) !== -1
                ) {
                    if (typeof tmpResults[key]==="undefined") {
                        tmpResults[key] = fullContent[key];
                    }
                }
            });
        } else {
            // if (tmp_countries.length>0) {
            //     this.setState({isSearch : true});
            //     tmpResults = fullContent;
            // } else {
            //     this.setState({isSearch : false});
            //     tmpResults = latestContent;
            // }
            if (tmp_years.length>0) {
                this.setState({isSearch : true});
                tmpResults = fullContent;
            } else {
                this.setState({isSearch : false});
                tmpResults = latestContent;
            }
        }
        // if (tmp_countries.length>0) {
        //     let tmpResultsFiltered = {};
        //     Object.keys(tmpResults).map(key => {
        //         if (tmp_countries.includes(tmpResults[key].country.code)) {
        //             if (typeof tmpResultsFiltered[key]==="undefined") {
        //                 tmpResultsFiltered[key] = tmpResults[key];
        //             }
        //         }
        //     });
        //     tmpResults = tmpResultsFiltered;
        // }
        if (tmp_years.length>0) {
            let tmpResultsFiltered = {};
            // Object.keys(tmpResults).map(key => {
            //     if (tmp_years.includes(tmpResults[key].year.toString())) {
            //         if (typeof tmpResultsFiltered[key]==="undefined") {
            //             tmpResultsFiltered[key] = tmpResults[key];
            //         }
            //     }
            // });
            Object.keys(tmpResults).forEach(function(key) {
                if (tmp_years.includes(tmpResults[key].year.toString())) {
                    if (typeof tmpResultsFiltered[key]==="undefined") {
                        tmpResultsFiltered[key] = tmpResults[key];
                    }
                }
            });
            tmpResults = tmpResultsFiltered;
        }
        this.setState({searchResults : tmpResults});
    }
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         country_clicked: []
    //     };
    // }
    check_country(country_code) {
        let tmp_countries = this.state.country_clicked;
        let country_index = tmp_countries.indexOf(country_code);
        if (country_index!==-1) {
            tmp_countries.splice(country_index,1);
        } else {
            tmp_countries.push(country_code);
        }
        this.setState({
            country_clicked: tmp_countries
        });
        // console.log(tmp_countries);
        this.handleFilter();
    }
    check_year(year) {
        year = year.toString();
        let tmp_years = this.state.years_clicked;
        let year_index = tmp_years.indexOf(year);
        if (year_index!==-1) {
            tmp_years.splice(year_index,1);
        } else {
            tmp_years.push(year);
        }
        this.setState({
            years_clicked: tmp_years
        });
        this.handleFilter();
    }
    render() {
        const { t } = this.props;
        this.refreshLanguageVariables();
        return (
            <div className="sections">
                <section className={`people-list` + (this.state.isSearch ? ' search-state' : '')}>
                    <div className="people-intro">
                        <div className="container">
                            <div className="content">
                                <h1 className="title" dangerouslySetInnerHTML={{__html: t('Peoples - title')}}></h1>
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{__html: t('Peoples - intro')}}></p>
                                </div>
                            </div>
                            <div className="filters">
                                <div className="search-form">
                                    <input type="search" placeholder={t('Search stories')} value={this.state.searchStr} onChange={this.handleInput}/>
                                    <span className="btn" onClick={this.handleFilter}></span>
                                </div>
                                <div className="tags">
                                    {/*{Object.keys(countries).map(key =>(*/}
                                    {/*   <span key={key} className={`country ${key}` + (this.state.country_clicked.indexOf(key) !== -1 ? ' active' : '')} onClick={this.check_country.bind(this, key)}>{countries[key]}</span>*/}
                                    {/*    // <span key={key} className={`country ${key}`} data-id={`${key}`}>{countries[key]}</span>*/}
                                    {/*))}*/}
                                    {Object.keys(years).map(key =>(
                                       <span key={key} className={`year year-${key}` + (this.state.years_clicked.indexOf(key) !== -1 ? ' active' : '')} onClick={this.check_year.bind(this, key)}>{years[key]}</span>
                                        // <span key={key} className={`country ${key}`} data-id={`${key}`}>{countries[key]}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`people-featured` + (this.state.isSearch ? ' hidden' : '')}>
                        <div className="container">
                            <div className="container-in">
                                <h2 className="subtitle" dangerouslySetInnerHTML={{__html: t('Peoples - featured')}}></h2>
                                {/*<div className={`people-block country-${first_content.country.code}`}>*/}
                                <div className={`people-block year-${first_content.year}`}>
                                    <div className="img">
                                        <img src={first_content.img} alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="title">
                                            <NavLink to={`${process.env.PUBLIC_URL}/peoples/${first_content_key}`}>{first_content.title}</NavLink>
                                        </h3>
                                        <div className="content">
                                            {first_content.intro}
                                        </div>
                                        <div className="bottom">
                                            <div className="tags">
                                                {/*<span className={`country active ${first_content.country.code}`}>{first_content.country.name}</span>*/}
                                                <span className={`year active year-${first_content.year}`}>{first_content.year}</span>
                                            </div>
                                            <div className="author">
                                                {first_content.author.name}
                                                <img src={first_content.author.img} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="people-newest">
                        <div className="container">
                            <div className="container-in">
                                <h2 className="subtitle" dangerouslySetInnerHTML={{__html: t('Peoples - latest')}}></h2>
                                <div className="blocks">
                                    {Object.keys(this.state.searchResults).map(key =>(
                                        <div className={`people-block year-${fullContent[key].year}`} key={key} style={{backgroundImage: `url(`+(fullContent[key].img)+`)`}}>
                                            <div className="bg"></div>
                                            <div className="content">
                                                <div className="content-in">
                                                    <h3 className="title"><NavLink to={`${process.env.PUBLIC_URL}/peoples/${key}`}>{fullContent[key].title}</NavLink></h3>
                                                    <div className="text">
                                                        {fullContent[key].intro}
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="tags">
                                                            <span className={`year active year-${fullContent[key].year}`}>{fullContent[key].year}</span>
                                                        </div>
                                                        <div className="author">
                                                            {fullContent[key].author.name}
                                                            <img src={fullContent[key].author.img} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(Peoples);
