import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import { withTranslation } from 'react-i18next';
// import i18next from "i18next";
// import peoples_en from "../translations/peoples-en";
// import peoples_de from "../translations/peoples-de";
// import peoples_fr from "../translations/peoples-fr";
var req = require.context('../assets');
class Home extends Component {
    constructor() {
        super();
        this.introsectionRef = React.createRef();
    }
    scroll(ref) {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }
    componentDidMount() {
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }
        const slidesParentEls = document.querySelectorAll(".slides");
        slidesParentEls.forEach(function (el, index, listObj) {
            let slidesParent = el;
            let slides = slidesParent.getElementsByTagName("div");
            let currentSlide = 0;
            let slidesCount = slides.length;
            let slideInterval;
            let slidesSwitcherContainer = document.createElement('ul');
            let on_click = function (ev,i) {
                if (typeof slideInterval!=="undefined") {
                    clearInterval(slideInterval);
                }
                slides[currentSlide].classList.remove('active');
                slideSwitcher[currentSlide].classList.remove('active');
                var child_element  = this;
                var parent_element = child_element.parentNode;
                currentSlide = Array.prototype.indexOf.call(parent_element.children, child_element);
                slides[currentSlide].classList.add('active');
                slideSwitcher[currentSlide].classList.add('active');
            };
            for (let i = 0; i < slidesCount; i++) {
                let slideSw = document.createElement('li');
                if (i===0) {
                    slideSw.classList.add('active');
                }
                slidesSwitcherContainer.appendChild(slideSw);
                slideSw.onclick = on_click;
            }
            slidesParent.appendChild(slidesSwitcherContainer);
            let slideSwitcher = slidesSwitcherContainer.getElementsByTagName('li');
            slideInterval = setInterval(() => {
                slides[currentSlide].classList.remove('active');
                slideSwitcher[currentSlide].classList.remove('active');
                currentSlide = (currentSlide + 1) % slidesCount;
                slides[currentSlide].classList.add('active');
                slideSwitcher[currentSlide].classList.add('active');
            }, 3000);
        });
        // document.getElementById("main-banner-video").onended = function () {
        //     document.getElementsByTagName("BODY")[0].classList.remove('play-video');
        // }

    }
    playMainVideo() {
        // document.getElementById("main-banner-video").play();
        document.getElementsByTagName("BODY")[0].classList.add('play-video');
        let video_iframe = document.getElementById('video-player-iframe');
        if (video_iframe!==null && video_iframe.src.indexOf('autoplay=1')<0) {
            let video_iframe_src = video_iframe.src;
            let tmp_connector = (video_iframe_src.indexOf('?') > 0) ? "&" : "?";
            video_iframe.src = video_iframe_src + tmp_connector + 'autoplay=1';
        }
    }


    render() {
        const { t } = this.props;
        //todo tmp
        // var curr_lang = i18next.language;
        var video_url = t('Home - Video');
        var video_id = '';
        var video_tag = '';
        var video_play_class = '';
        if (video_url.indexOf('watch?v=')>0) {
            video_id = video_url.slice(video_url.indexOf('watch?v=')+8);
            if (video_id.indexOf('&')>0) {
                video_id = video_id.slice(0,video_id.indexOf('&'));
            }
        } else if (video_url.indexOf('//youtu.be/')>0) {
            video_id = video_url.slice(video_url.indexOf('//youtu.be/')+11);
            if (video_id.indexOf('?')>0) {
                video_id = video_id.slice(0,video_id.indexOf('?'));
            }
        }
        if (video_id!=="") {
            video_tag = '<iframe id="video-player-iframe" width="1920" height="1080" src="https://www.youtube-nocookie.com/embed/'+video_id+'?controls=0&rel=0&showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>';
            video_play_class = ' with-video';
        }
        return (
            <div className="sections">
                <section className="main-banner">
                    <div className="video-iframe" dangerouslySetInnerHTML={{__html: video_tag}}></div>
                    <div className="main-banner-poster"></div>
                    <div className="container">
                        <div className="text">
                            <h1 dangerouslySetInnerHTML={{__html: t('Home - Key visual area - Headline')}}></h1>
                            <div className={`text-bottom`+video_play_class}>
                                <span id="main-banner-video-btn" dangerouslySetInnerHTML={{__html: t('Watch video')}} onClick={this.playMainVideo}></span>
                                <div className="scroll-down" onClick={() => {this.scroll(this.introsectionRef)}} >
                                    <img src={req('./arrow.svg')} alt="&arrdown;"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="intro-1" ref={this.introsectionRef}>
                    <div className="container">
                        <div className="container-in">
                            <div className="block">
                                <div className="img">
                                </div>
                                <div className="text">
                                    <div className="text-in">
                                        <p dangerouslySetInnerHTML={{__html: t('Home - Fader - Subhead 1')}}></p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                            <div className="block">
                                <div className="text">
                                    <div className="text-in">
                                        <p dangerouslySetInnerHTML={{__html: t('Home - Fader - Subhead 2')}}></p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <hr/>
                                        <NavLink to={`${process.env.PUBLIC_URL}/peoples`} dangerouslySetInnerHTML={{__html: t('Home - Fader - Subhead 2 - Link Name')}}></NavLink>
                                    </div>
                                </div>
                                <div className="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="intro-2">
                    <div className="container">
                        <div className="container-in">
                            <div className="img">
                                <img src={require('../assets/phone-app.jpg')} alt="Phone app"/>
                            </div>
                            <div className="text">
                                <p dangerouslySetInnerHTML={{__html: t('Home - Fader - Subhead 3')}}></p>
                                <strong dangerouslySetInnerHTML={{__html: t('Home - Fader - Subhead 3b')}}></strong>
                                <div className="links">
                                    <a target="_blank" rel="noopener noreferrer" href={t('App Store link')}><img src={require('../assets/app-store.png')} alt="App Store"/></a>
                                    <a target="_blank" rel="noopener noreferrer" href={t('Google Play link')}><img src={require('../assets/google-play.png')} alt="Google Play"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="intro-3">
                    <div className="text">
                        <div className="container">
                            <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home - UNDP - SDG 6 Title')}}></h3>
                            <div className="text-in">
                                <p>
                                    <span dangerouslySetInnerHTML={{__html: t('Home - UNDP - SDG 6')}}></span><br/>
                                    <strong dangerouslySetInnerHTML={{__html: t('Home - UNDP - SDG 6b')}}></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="intro-4">
                    <div className="container">
                        <div className="text">
                            <div className="intro">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home numbers 1')}}></h3>
                                <p dangerouslySetInnerHTML={{__html: t('Home numbers 2')}}></p>
                            </div>
                            <div className="blocks">
                                <div className="block">
                                    <div className="icon">
                                        <span className="number" dangerouslySetInnerHTML={{__html: t('Home numbers 6')}}></span>
                                    </div>
                                    <strong dangerouslySetInnerHTML={{__html: t('Home numbers 7')}}></strong>
                                    <span dangerouslySetInnerHTML={{__html: t('Home numbers 8')}}></span>
                                </div>
                                <div className="block">
                                    <div className="icon">
                                        <span className="number" dangerouslySetInnerHTML={{__html: t('Home numbers 12')}}></span>
                                    </div>
                                    <strong dangerouslySetInnerHTML={{__html: t('Home numbers 13')}}></strong>
                                    <span dangerouslySetInnerHTML={{__html: t('Home numbers 14')}}></span>
                                </div>
                                <div className="block">
                                    <div className="icon">
                                        <span className="number" dangerouslySetInnerHTML={{__html: t('Home numbers 9')}}></span>
                                    </div>
                                    <strong dangerouslySetInnerHTML={{__html: t('Home numbers 10')}}></strong>
                                    <span dangerouslySetInnerHTML={{__html: t('Home numbers 11')}}></span>
                                </div>
                            </div>
                            <div className="info-block">
                                <span className="number" dangerouslySetInnerHTML={{__html: t('Home numbers 3')}}></span>
                                <strong dangerouslySetInnerHTML={{__html: t('Home numbers 4')}}></strong>
                                <span dangerouslySetInnerHTML={{__html: t('Home numbers 5')}}></span>
                            </div>


                        </div>

                    </div>
                </section>
                <section className="intro-5 slides">
                    <div className="active" style={{ backgroundImage: `url(`+require('../assets/intro-5a.jpg')+`)` }}>
                        <img src={require('../assets/intro-5a.jpg')} alt=""/>
                    </div>
                    <div style={{ backgroundImage: `url(`+require('../assets/intro-5b.jpg')+`)` }}></div>
                    <div style={{ backgroundImage: `url(`+require('../assets/intro-5c.jpg')+`)` }}></div>
                </section>
                <section className="intro-6">
                    <div className="container">
                        <div className="blocks">
                            <div className="block">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home - Program - What is Waterdrop? Title')}}></h3>
                                <div className="text" dangerouslySetInnerHTML={{__html: t('Home - Program - What is Waterdrop?')}}></div>
                            </div>
                            <div className="block">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home - Program - Drops Title')}}></h3>
                                <div className="text" dangerouslySetInnerHTML={{__html: t('Home - Program - Drops')}}></div>
                            </div>
                            <div className="block">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home - Program - Who is Waterdrop aimed at? Title')}}></h3>
                                <div className="text" dangerouslySetInnerHTML={{__html: t('Home - Program - Who is Waterdrop aimed at?')}}></div>
                            </div>
                            <div className="block">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: t('Home - Program - Ranking Title')}}></h3>
                                <div className="text">
                                    <div className="col">
                                        <img className="icon" src={require('../assets/icon-rank-1.png')} alt="icon"/>
                                        <p>&nbsp;</p>
                                        <p dangerouslySetInnerHTML={{__html: t('Home - Program - Ranking 1')}}></p>
                                    </div>
                                    <div className="col">
                                        <img className="icon" src={require('../assets/icon-rank-2.png')} alt="icon"/>
                                        <p>&nbsp;</p>
                                        <p dangerouslySetInnerHTML={{__html: t('Home - Program - Ranking 2')}}></p>
                                    </div>
                                    <div className="col">
                                        <img className="icon" src={require('../assets/icon-rank-3.png')} alt="icon"/>
                                        <p>&nbsp;</p>
                                        <p dangerouslySetInnerHTML={{__html: t('Home - Program - Ranking 3')}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(Home);
