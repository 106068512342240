import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import faq_en from "../translations/faq-en";
import faq_fr from "../translations/faq-fr";
import faq_de from "../translations/faq-de";
import faq_sv from "../translations/faq-sv";

var fullContent = faq_en;

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.refreshLanguageVariables();
        this.state = {
            faq_clicked: []
        };
    }
    refreshLanguageVariables() {
        //todo tmp
        var curr_lang = i18next.language;
        if (curr_lang==="en") {
            fullContent = faq_en;
        } else if (curr_lang==="de") {
            fullContent = faq_de;
        } else if (curr_lang==="fr") {
            fullContent = faq_fr;
        } else if (curr_lang==="sv") {
            fullContent = faq_sv;
        }
    }
    check_faq(faq_code) {
        let tmp_faqs = this.state.faq_clicked;
        let faq_index = tmp_faqs.indexOf(faq_code);
        if (faq_index!==-1) {
            tmp_faqs.splice(faq_index,1);
        } else {
            tmp_faqs.push(faq_code);
        }
        this.setState({
            faq_clicked: tmp_faqs
        });
    }
    render() {
        const { t } = this.props;
        this.refreshLanguageVariables();
        return (
            <div className="sections">
                <section className="faq-section">
                    <div className="container">
                        <h1 className="title" dangerouslySetInnerHTML={{__html: t('Frequently Asked Questions')}}></h1>
                        <div className="faqs">
                            {Object.keys(fullContent).map(key =>(
                                <div className="faq" key={`faq`+key}>
                                    <div className={`q` + (this.state.faq_clicked.indexOf(key) !== -1 ? ' active' : '')} onClick={this.check_faq.bind(this, key)} dangerouslySetInnerHTML={{__html: fullContent[key].q}}></div>
                                    <div className="a" dangerouslySetInnerHTML={{__html: fullContent[key].a }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(FAQ);
