import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
// import i18next from "i18next";
var req = require.context('../assets');

class About extends Component {
    constructor() {
        super();
        this.aboutSection1 = React.createRef();
        this.aboutSection2 = React.createRef();
        this.aboutSection3 = React.createRef();
        this.aboutSection4 = React.createRef();
        this.aboutSection5 = React.createRef();
        this.aboutSection6 = React.createRef();
        this.aboutSection7 = React.createRef();
        this.aboutSection8 = React.createRef();
        this.aboutSection9 = React.createRef();
        this.aboutSection10 = React.createRef();
    }
    scroll(ref) {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }
    playMainVideo() {
        // document.getElementById("about-video").play();
        document.getElementsByTagName("BODY")[0].classList.add('play-video');
        let video_iframe = document.getElementById('video-player-iframe');
        if (video_iframe!==null && video_iframe.src.indexOf('autoplay=1')<0) {
            let video_iframe_src = video_iframe.src;
            let tmp_connector = (video_iframe_src.indexOf('?') > 0) ? "&" : "?";
            video_iframe.src = video_iframe_src + tmp_connector + 'autoplay=1';
        }
    }
    // componentDidMount() {
    //     document.getElementById("about-video").onended = function () {
    //         document.getElementsByTagName("BODY")[0].classList.remove('play-video');
    //     }
    // }

    render() {
        const { t } = this.props;
        // var curr_lang = i18next.language;
        var video_url = t('Programme - About - Video');
        var video_id = '';
        var video_tag = '';
        var video_play_class = '';
        if (video_url.indexOf('watch?v=')>0) {
            video_id = video_url.slice(video_url.indexOf('watch?v=')+8);
            if (video_id.indexOf('&')>0) {
                video_id = video_id.slice(0,video_id.indexOf('&'));
            }
        } else if (video_url.indexOf('//youtu.be/')>0) {
            video_id = video_url.slice(video_url.indexOf('//youtu.be/')+11);
            if (video_id.indexOf('?')>0) {
                video_id = video_id.slice(0,video_id.indexOf('?'));
            }
        }
        if (video_id!=="") {
            video_tag = '<iframe id="video-player-iframe" width="1920" height="1080" src="https://www.youtube-nocookie.com/embed/'+video_id+'?controls=0&rel=0&showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>';
            video_play_class = ' with-video';
        }

        return (
            <div className="sections about-page">
                <section className="about about-video-section">
                    <div className="video-iframe" dangerouslySetInnerHTML={{__html: video_tag}}></div>
                    <div className="about-poster"></div>
                    <div className={`about-bottom`+video_play_class}>
                        <span id="about-video-btn" dangerouslySetInnerHTML={{__html: t('Watch video')}} onClick={this.playMainVideo}></span>
                        <div className="scroll-down-arr" onClick={() => {this.scroll(this.aboutSection1)}} >
                            <img src={req('./arrow.svg')} alt="&arrdown;"/>
                        </div>
                    </div>
                </section>
                <section className="about about-1" ref={this.aboutSection1}>
                    <div className="container">
                        <div className="text">
                            <h3 className="title" dangerouslySetInnerHTML={{__html: t('Programme - About')}}></h3>
                            <p>&nbsp;</p>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About 2')}}></p>
                            <p>&nbsp;</p>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About 3')}}></p>
                        </div>
                    </div>
                    <span className={'scroll-down'} onClick={() => {this.scroll(this.aboutSection2)}}>scroll down</span>
                </section>
                <section className="about about-2" ref={this.aboutSection2}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title2'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 1 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 1')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 1 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection1)}}/>
                        1
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection3)}}/>
                    </span>
                </section>
                <section className="about about-3" ref={this.aboutSection3}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 2 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 2')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 2 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection2)}}/>
                        2
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection4)}}/>
                    </span>
                </section>
                <section className="about about-4" ref={this.aboutSection4}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 3 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 3')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 3 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection3)}}/>
                        3
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection5)}}/>
                    </span>
                </section>
                <section className="about about-5" ref={this.aboutSection5}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 4 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 4')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 4 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection4)}}/>
                        4
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection6)}}/>
                    </span>
                </section>
                <section className="about about-6" ref={this.aboutSection6}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 5 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 5')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 5 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection5)}}/>
                        5
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection7)}}/>
                    </span>
                </section>
                <section className="about about-7" ref={this.aboutSection7}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 6 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 6')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 6 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection6)}}/>
                        6
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection8)}}/>
                    </span>
                </section>
                <section className="about about-8" ref={this.aboutSection8}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 7 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 7')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 7 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection7)}}/>
                        7
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection9)}}/>
                    </span>
                </section>
                <section className="about about-9" ref={this.aboutSection9}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 8 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 8')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 8 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection8)}}/>
                        8
                        <img src={req('./arrow.svg')} alt="&arrdown;" onClick={() => {this.scroll(this.aboutSection10)}}/>
                    </span>
                </section>
                <section className="about about-10" ref={this.aboutSection10}>
                    <span className={'facts'}>facts</span>
                    <div className="container">
                        <div className={`text`}>
                            <h3 className={'title'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 9 Title')}}></h3>
                            <p dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 9')}}></p>
                        </div>
                    </div>
                    <span className={'subtext'} dangerouslySetInnerHTML={{__html: t('Programme - About - Facts - 9 - subtext')}}></span>
                    <span className={'switcher'}>
                        <img src={req('./arrow-up.svg')} alt="&arrup;" onClick={() => {this.scroll(this.aboutSection9)}}/>
                        9
                    </span>
                </section>
            </div>
        );
    }
}

export default withTranslation()(About);
