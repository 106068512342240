import React, { Component } from "react";
import {withTranslation} from "react-i18next";

class WaterTower extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="sections">
                <section className="water-tower-1">
                    <div className="container">
                        <h1 className="title" dangerouslySetInnerHTML={{__html: t('Water Tower Title')}}></h1>
                        <div className="block">
                            <img src={require('../assets/water-tower-1.jpg')} alt=""/>
                            <div className="text">
                                <p dangerouslySetInnerHTML={{__html: t('Water Tower 1')}}></p>
                                <hr/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="water-tower-2">
                    <div className="container">
                        <div className="blocks">
                            <div className="block">
                                <div className="img">
                                    <img src={require('../assets/water-tower-2.jpg')} alt=""/>
                                </div>
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{__html: t('Water Tower 2')}}></p>
                                    <p>&nbsp;</p>
                                    <p dangerouslySetInnerHTML={{__html: t('Water Tower 3')}}></p>
                                </div>
                            </div>
                            <div className="block">
                                <div className="img">
                                    <img src={require('../assets/water-tower-3.jpg')} alt=""/>
                                </div>
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{__html: t('Water Tower 4')}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="water-tower-1">
                    <div className="container">
                        <div className="block">
                            <img src={require('../assets/water-tower-4.jpg')} alt=""/>
                            <div className="text">
                                <p dangerouslySetInnerHTML={{__html: t('Water Tower 5')}}></p>
                                <p>&nbsp;</p>
                                <p dangerouslySetInnerHTML={{__html: t('Water Tower 6')}}></p>
                                <hr/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(WaterTower);
